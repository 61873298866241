import { Container } from '../../container';
import { Embed } from '../../embed';
import type { FeatureEmbedProps } from './types';

export const FeatureEmbed = ({ title, embed, as: Header = 'h2' }: FeatureEmbedProps) => {
  return (
    <div className="flex flex-col text-center justify-center items-center w-full py-16 md:py-24">
      <Container className="flex flex-col items-center justify-center">
        <Header className="font-bold text-gray-900 text-2xl max-w-[90%] md:max-w-[75%] md:text-4xl">{title}</Header>
        <Embed
          className="max-w-[800px] mt-12"
          title={title}
          {...embed}
        />
      </Container>
    </div>
  );
};
