import { createClient } from '@sanity/client';

export const sanity = createClient({
  apiVersion: '2021-08-31',
  projectId: 'pbg9wd5k',
  dataset: 'production',
  useCdn: process.env.NEXT_PUBLIC_APP_ENV === 'production',
});

export * as queries from './queries';
export * from './components';
