import { AppHeader, AppFooter } from '@/components';
import type { LayoutProps } from '../types';

export const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col flex-grow flex-shrink">
      <AppHeader />
      <main className="w-full flex flex-col flex-grow flex-shrink">{children}</main>
      <AppFooter />
    </div>
  );
};
