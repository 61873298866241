import type { AlertProps } from './types';

export const Alert = ({ className, children }: AlertProps) => {
  return (
    <div
      className={[
        'border-2',
        'px-6',
        'py-3',
        'rounded-md',
        'text-base',
        'text-center',
        'md:text-left',
        ...(className ? [className] : []),
      ].join(' ')}
    >{children}</div>
  );
};
