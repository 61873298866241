import * as React from 'react';
import type { LoaderProps } from './types';

export const Loader = ({ light = false, customSize = false, className }: LoaderProps) => {
  return (
    <div
      style={{ borderTopColor: 'transparent' }}
      className={[
        'border-top-transparent',
        ...(customSize ? [] : [
          'w-16',
          'h-16',
          'border-4',
        ]),
        light ? 'border-white' : 'border-primary-400',
        'border-solid',
        'rounded-full',
        'animate-spin',
        ...(className ? [className] : []),
      ].join(' ')}
    />
  );
};
