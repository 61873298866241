import type { BlogCategoriesProps } from './types';

export const BlogCategories = ({ categories, className }: BlogCategoriesProps) => {
  return (
    <div className={['flex flex-row items-center justify-center space-x-2', ...(className ? [className] : [])].join(' ')}>
      {categories.map((category, index) => (
        <div className="inline-flex rounded-full bg-primary-500/20 px-3 py-1 font-medium text-sm text-primary-500" key={index}>{category}</div>
      ))}
    </div>
  );
};
