import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Disclosure } from '@headlessui/react';
import { Container } from '../container';
import type { FAQProps } from './types';

export const FAQ = ({ title, items, className, unmountAnswers = false }: FAQProps) => {
  return (
    <Container className={className}>
      <div className="mx-auto w-full">
        <h2 className="font-bold text-gray-900 text-2xl text-center md:text-4xl">{title}</h2>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {items.map(item => (
            <Disclosure as="div" key={item.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                      <span className="font-medium text-gray-900">{item.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        <ChevronDownIcon
                          className={[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform'].join(' ')}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel unmount={unmountAnswers} as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">{item.answer}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </Container>
  );
};
