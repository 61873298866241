import { CheckIcon } from '@heroicons/react/20/solid';
import type { FeatureListProps } from './types';

export const FeatureList = ({ items, className }: FeatureListProps) => {
  return (
    <ul
      className={['text-left', ...(className ? [className] : [])].join(' ')}
      role="list"
    >
      {items.map((item, index) => (
        <li
          className="block relative"
          role="list-item"
          key={index}
        >
          <CheckIcon className="w-5 h-5 absolute left-[-1.5rem] top-[-1rem] text-primary-500" />
          {item}
        </li>
      ))}
    </ul>
  );
};
