import imageUrlBuilder from '@sanity/image-url';
import spacetime from 'spacetime';
import Image from 'next/image';
import { sanity } from '@/interfaces/sanity';
import { Link } from '@/components';
import type { BlogListItemProps } from './types';

export const Item = ({ article }: BlogListItemProps) => {
  const timestamp = spacetime(new Date(article.published_at)).format('{month} {date-ordinal}, {year}');

  return (
    <li key={article.slug.current} className="text-left">
      <Link href={`/blog/${article.slug.current}`} className="hover:!text-primary-500">
        <div className="aspect-[6/4] relative rounded overflow-hidden bg-gray-300 mb-3">
          {article.image && (
            <Image
              src={imageUrlBuilder(sanity).image(article.image).toString()}
              alt={article.title}
              className="object-cover"
              fill
            />
          )}
        </div>
        <div className="uppercase text-slate-500 font-medium text-sm md:text-base">{timestamp}</div>
        <div className="font-bold text-md md:text-lg">{article.title}</div>
      </Link>
    </li>
  )
}
