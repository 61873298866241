import type { TeamListProps, SocialPlatforms } from './types';
import Image from 'next/image';
import { t } from '@lingui/macro';
import { Linkedin, Twitter } from '@ricons/fa';
import type { ReactNode } from 'react';

export const TeamList = ({ className, description, items, title }: TeamListProps) => {
  const socials: Record<SocialPlatforms, ReactNode> = {
    LinkedIn: <Linkedin className="w-5 h-5" />,
    Twitter: <Twitter className="w-5 h-5" />,
  };

  return (
    <div className={className}>
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
          {description && (
            <p className="mt-6 text-lg leading-8 text-gray-600">{description}</p>
          )}
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
        >
          {items.map((person) => (
            <li key={person.name} className="flex flex-col gap-6 xl:flex-row">
              <Image
                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                src={person.image}
                alt={t`Photo of ${person.name}`}
              />
              <div className="flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                {person.bio && (
                  <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                )}
                {person.socials && (
                  <ul role="list" className="mt-6 flex gap-x-6">
                    {person.socials.map((social, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={social.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">{social.name}</span>
                            {socials[social.name]}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}

              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
