import type { GetServerSideProps, GetStaticPaths, GetStaticPathsResult, GetStaticProps, GetStaticPropsResult } from 'next';
import { en, de, fr, es, ja } from 'make-plural/plurals';
import type { I18n, Messages } from '@lingui/core';
import { i18n } from '@lingui/core';
import type { Locale } from '@/types';

export function initTranslation(i18n: I18n) {
  i18n.loadLocaleData({
    en: { plurals: en },
    de: { plurals: de },
    fr: { plurals: fr },
    es: { plurals: es },
    ja: { plurals: ja },
  });

  // Load dummy data to fix SSG.
  i18n.load('en', {});
  i18n.activate('en');
}

export const activateLocale = async (locale: Locale) => {
  const { messages } = await import(`./${locale}/messages.po`);
  i18n.load(locale, messages);
  i18n.activate(locale);
};

export function withLocale(type: 'static', grab?: GetStaticProps): GetStaticProps;
export function withLocale(type: 'server', grab?: GetServerSideProps): GetServerSideProps;
export function withLocale(type: 'static' | 'server', grab?: GetStaticProps | GetServerSideProps): GetStaticProps | GetServerSideProps {
  return async function (context: any) {
    // go away
    let { locale, defaultLocale = 'en' } = context;
    const root = (await grab?.(context)) ?? {
      props: {},
    };
    let translations: Messages = {};

    if (!locale) {
      locale = defaultLocale;
    }

    if (process.env.NODE_ENV === 'production') {
      translations = (await import(`./${locale}/messages.po`)).messages;
    } else {
      translations = (await import(`@lingui/loader!./${locale}/messages.po`)).messages;
    }

    return {
      ...root,
      props: {
        // @ts-expect-error Just don't use this helper with redirects...
        ...(root.props ?? {}),
        translations,
      },
    };
  };
}

export const withI18n =
  (grab?: GetStaticPaths): GetStaticPaths =>
  async context => {
    const { locales, defaultLocale = 'en' } = context;
    const root = (await grab?.(context)) ?? {
      fallback: 'blocking',
      paths: [],
    };

    return {
      ...root,
      paths: root.paths.reduce<GetStaticPathsResult['paths']>((arr, path) => {
        return [
          ...arr,
          ...(locales ?? [defaultLocale]).map(code => ({
            ...(typeof path === 'string'
              ? {
                  params: {},
                }
              : path),
            locale: code,
          })),
        ];
      }, []),
    };
  };
