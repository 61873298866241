import { createContext, useEffect, useState } from 'react';
import { Platform } from '@/enums';
import type { PlatformProviderProps, PlatformProviderState } from './types';

export const PlatformContext = createContext<PlatformProviderState | null>(null);

export const PlatformProvider = ({ children }: PlatformProviderProps) => {
  const [platform, set] = useState(Platform.Windows);

  useEffect(() => {
    const match = window.navigator.userAgent.match(/(Win|Mac|Linux|iPad|iPhone|iPod|Android)/);
    const os = (match && match[1]) ?? '';

    if (
      ['iPad', 'iPhone', 'iPod'].indexOf(os) !== -1 ||
      (os === 'Mac' && window.navigator.maxTouchPoints && window.navigator.maxTouchPoints > 1)
    ) {
      set(Platform.iOS);
    } else if (os === 'Win') {
      set(Platform.Windows);
    } else if (os === 'Mac') {
      set(Platform.macOS);
    } else if (os === 'Linux') {
      set(Platform.Linux);
    } else if (os === 'Android') {
      set(Platform.Android);
    }
  }, []);

  return <PlatformContext.Provider value={{ platform, set }}>{children}</PlatformContext.Provider>;
};
