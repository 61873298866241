import type { Experiment } from './types';

export const current: string | null = null;

export const experiments: Experiment[] = [
  {
    id: '7uRYq6GnQ-mBGXquO_aEyw',
    name: 'Sales landing headline',
    page: '/sales/',
    variants: [
      {
        id: 0,
        name: 'Original',
        weight: 75,
      },
      {
        id: 1,
        name: 'Everyone can do sales demos',
        weight: 25,
      },
    ],
  },
];

export const getCookieString = (experiment: string, variant: number) => `${experiment}.${variant}`;

export const getCookieValue = (value: string) => {
  const [experiment, variant] = value.split('.');
  return { experiment, variant: parseInt(variant) };
};

export * from './types';
