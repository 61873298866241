import { useRouter } from 'next/router';
import { useEffect } from 'react';
import nprogress from 'nprogress';
import { useRudderstack } from '@/hooks';

export const Progress = () => {
  const { rudderstack } = useRudderstack();
  const router = useRouter();

  useEffect(() => {
    nprogress.configure({ showSpinner: false });

    const handleRouteStart = () => nprogress.start();
    const handleRouteDone = () => {
      rudderstack()?.page();
      return nprogress.done();
    };

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);

  return null;
};
