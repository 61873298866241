import { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import type { TextRotateProps } from './types';

export const TextRotate = ({ children, items, speed = 1000 }: TextRotateProps) => {
  const [mounted, setMounted] = useState(false);
  const [index, setIndex] = useState(0);

  const word = items[index] ?? items[0];

  useInterval(() => {
    let newIndex = index + 1;

    if (newIndex >= items.length) {
      newIndex = 0;
    }

    setIndex(newIndex);
  }, speed);

  useEffect(() => setMounted(true), []);

  return (
    <span>
      {children}{' '}
      <span className="block relative w-full text-center">
        {mounted ? (
          <>
            <span className="opacity-0">{items[0]}</span>
            {items.map((item, itemIndex) => (
              <span
                className={[
                  'block w-full h-full absolute transition-all ease-in-out duration-300 top-0',
                  ...(index === itemIndex ? [
                    'opacity-1',
                  ] : [
                    'opacity-0',
                    'pointer-events-none'
                  ]),
                ].join(' ')}
                style={{ top: `calc(${100 * itemIndex}% - ${100 * index}%)` }}
                aria-hidden="true"
                key={item}
              >{item}</span>
            ))}
          </>
        ) : <>{word}</>}
      </span>
    </span>
  );
};
