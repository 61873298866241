import { Container } from '../../container';
import { BlogListProps } from './types';
import { Item } from './item';

export const BlogList = ({ articles }: BlogListProps) => {
  return (
    <Container>
      <ul className="grid grid-rows-[auto] auto-cols-fr gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-8">
        {articles.map(article => (
          <Item article={article} key={article.slug.current} />
        ))}
      </ul>
    </Container>
  )
};
