import type { PageTitleProps } from './types';

export const PageTitle = ({ as = 'h1', id, title, subtitle, children, switchTitle = false, noPaddingBottom = false, minimalPadding = false }: PageTitleProps) => {
  const Title = switchTitle ? 'p' : as;
  const Subtitle = switchTitle ? as : 'p';

  let padding = 'py-16 sm:py-24';

  if (noPaddingBottom) {
    padding = 'pt-16 sm:pt-24';
  }

  if (minimalPadding) {
    padding = 'py-8';
  }

  return (
    <div className={`mx-auto max-w-7xl px-4 ${padding} sm:px-6 lg:px-8`}>
      <div className="flex flex-col items-center text-center">
        {((subtitle && switchTitle) || !switchTitle) && (
          <Title
            className="text-lg font-semibold text-primary-600 uppercase"
            id={!switchTitle ? id : undefined}
          >{switchTitle ? subtitle : title}</Title>
        )}
        {(subtitle || switchTitle) && (
          <Subtitle
            className="mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:max-w-5xl lg:text-5xl"
            id={switchTitle ? id : undefined}
          >{switchTitle ? title : subtitle}</Subtitle>
        )}
        {children && (
          <p className="mx-auto mt-5 max-w-2xl text-xl text-gray-500">{children}</p>
        )}
      </div>
    </div>
  );
};
