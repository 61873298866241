import type { PortableTextReactComponents } from '@portabletext/react';
import type { SanityClient } from '@sanity/client';
import { PortableText } from '@portabletext/react';
import imageUrlBuilder from '@sanity/image-url';
import { t } from '@lingui/macro';
import { Embed } from '@/components';
import Link from 'next/link';

export const sanityComponents = (sanity: SanityClient): Partial<PortableTextReactComponents> => ({
  types: {
    image: ({ value }) => {
      if (!value?.asset?._ref) {
        return null;
      }

      return (
        <img
          src={imageUrlBuilder(sanity).image(value).toString()}
          className="block w-full h-auto rounded-lg"
          alt={value.alt ?? ''}
          loading="lazy"
        />
      );
    },
    block_image: ({ value }) => {
      if (!value?.asset?._ref) {
        return null;
      }

      return (
        <>
          <img
            src={imageUrlBuilder(sanity).image(value).toString()}
            className="block w-full h-auto rounded-lg !mb-3"
            alt={value.alt ?? ''}
            loading="lazy"
          />
          <div className="prose mb-0 !max-w-none text-sm text-center">
            <PortableText value={value.caption} />
          </div>
        </>
      );
    },
    block_embed: ({ value }) => {
      return (
        <>
          <Embed className="max-w-[800px] !mb-3" title={t`VideoCom Embed`} url={value.url} />
          <div className="prose mb-0 !max-w-none text-sm text-center">
            <PortableText value={value.caption} />
          </div>
        </>
      );
    },
  },
  marks: {
    link: ({ text, value }) => {
      let href = value?.href ?? 'https://videocom.com';
      let internal = false;

      // Dumb, but I don't have time so it works
      if (href.startsWith('https://videocom.com/')) {
        href = href.replace('https://videocom.com/', '/');
        internal = true;
      } else if (href.startsWith('https://www.videocom.com/')) {
        href = href.replace('https://www.videocom.com/', '/');
        internal = true;
      } else if (href.startsWith('https://www.videocom.com')) {
        href = href.replace('https://www.videocom.com', '/');
        internal = true;
      } else if (href.startsWith('https://videocom.com')) {
        href = href.replace('https://videocom.com', '/');
        internal = true;
      }

      return (
        <Link
          href={href}
          {...(internal
            ? {}
            : {
                rel: 'noopener noreferrer',
                target: '_blank',
              })}
        >
          {text}
        </Link>
      );
    },
  },
});
