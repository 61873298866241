import { Fragment } from 'react';
import { t, Trans } from '@lingui/macro';
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid';
import { Button } from '@/components/button';
import type { PricingComparisonTableProps } from './types';

export const PricingComparisonTable = ({
  plans,
  sections,
  billingPeriod,
}: PricingComparisonTableProps) => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        {/* xs to lg */}
        <div className="mx-auto max-w-2xl space-y-16 lg:hidden">
          {plans.map((plan, planIdx) => (
            <section key={plan.id}>
              <div className="mb-8 px-4">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  {plan.name}
                </h2>
                <div className="flex items-center mt-4">
                  <span className="text-gray-900 text-4xl font-bold tracking-tight">
                    {plan.price.monthly > 0 || !!plan.price.onetime
                      ? `\$${!!plan.price.onetime
                        ? plan.price.onetime
                        : plan.price[billingPeriod]
                      }`
                      : plan.price.custom ?? t`Free`}
                  </span>
                  {(plan.price.monthly > 0 || !!plan.price.onetime) && (
                    <div className="ml-2">
                      <span className="text-base font-medium text-gray-500">
                        {t`USD`}{' '}
                        {plan.price.onetime === undefined && (
                          <>
                            {t`/month`}{' '}
                            {plan.price.unit !== undefined && plan.price.unit}
                          </>
                        )}
                      </span>
                    </div>
                  )}
                </div>
                <p className="mt-6 text-sm text-gray-500" />
                <Button href={plan.button.href} block>
                  {plan.button.text}
                </Button>
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="border-t border-gray-200 bg-gray-50 py-3 px-4 text-left text-sm font-medium text-gray-900">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        {t`Feature`}
                      </th>
                      <th className="sr-only" scope="col">
                        {t`Included`}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th
                          className="py-5 px-4 text-left text-sm font-normal text-gray-500"
                          scope="row"
                        >
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.plans[plan.id] === 'string' ? (
                            <span className="block text-right text-sm text-gray-700">
                              {feature.plans[plan.id]}
                            </span>
                          ) : (
                            <>
                              {feature.plans[plan.id] === true ? (
                                <CheckIcon
                                  className="ml-auto h-5 w-5 text-primary-600"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="ml-auto h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.plans[plan.id] === true ? t`Yes` : t`No`}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div
                className={[
                  planIdx < plans.length - 1 ? 'py-5 border-b' : 'pt-5',
                  'border-t border-gray-200 px-4',
                ].join(' ')}
              >
                <Button href={plan.button.href} block>
                  {plan.button.text}
                </Button>
              </div>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="h-px w-full table-fixed">
            <caption className="sr-only">{t`Pricing plan comparison`}</caption>
            <thead className="sticky z-10 top-16 bg-white">
              <tr>
                <th
                  className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                  scope="col"
                >
                  <span className="sr-only">{t`Feature by`}</span>
                  <span>{t`Plans`}</span>
                </th>

                {plans.map((plan) => (
                  <th
                    key={plan.id}
                    className="w-1/4 px-6 py-4 text-left text-lg font-medium leading-6 text-gray-900"
                    scope="col"
                  >
                    {plan.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
              <tr>
                <th
                  className="py-8 px-6 text-left align-top text-sm font-medium text-gray-900"
                  scope="row"
                >
                  {t`Pricing`}
                </th>
                {plans.map((plan) => (
                  <td key={plan.id} className="h-full py-8 px-6 align-top">
                    <div className="relative table h-full w-full">
                      <div className="flex items-center">
                        <span className="text-gray-900 text-4xl font-bold tracking-tight">
                          {plan.price.monthly > 0 || !!plan.price.onetime
                            ? `\$${!!plan.price.onetime
                              ? plan.price.onetime
                              : plan.price[billingPeriod]
                            }`
                            : plan.price.custom ?? t`Free`}
                        </span>
                        {(plan.price.monthly > 0 || !!plan.price.onetime) && (
                          <div className="ml-2">
                            <span className="text-base font-medium text-gray-500">
                              {t`USD`}{' '}
                              {plan.price.onetime === undefined && (
                                <>
                                  {t`/month`}{' '}
                                  {plan.price.unit !== undefined && plan.price.unit}
                                </>
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      <Button href={plan.button.href} block className="mt-4">
                        {plan.button.text}
                      </Button>
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        className="py-5 px-6 text-left text-sm font-normal text-gray-500"
                        scope="row"
                      >
                        {feature.name}
                      </th>
                      {plans.map((plan) => (
                        <td key={plan.id} className="py-5 px-6">
                          {typeof feature.plans[plan.id] === 'string' ? (
                            <span className="block text-sm text-gray-700">
                              {feature.plans[plan.id]}
                            </span>
                          ) : (
                            <>
                              {feature.plans[plan.id] === true ? (
                                <CheckIcon
                                  className="h-5 w-5 text-primary-600"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.plans[plan.id] === true ? (
                                  <Trans>Included in {plan.name}</Trans>
                                ) : (
                                  <Trans>Not included in {plan.name}</Trans>
                                )}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  {t`Choose your plan`}
                </th>
                {plans.map((plan) => (
                  <td key={plan.id} className="px-6 pt-5">
                    <Button href={plan.button.href} block className="mt-4">
                      {plan.button.text}
                    </Button>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
