import { useEffect } from 'react';
import type { CrispProviderProps } from './types';

export const CrispProvider = ({ children }: CrispProviderProps) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID;

      // To stop Crisp complaining about shims.
      window.$crisp.push(['safe', true]);

      const $script = document.createElement('script');
      $script.src = 'https://client.crisp.chat/l.js';
      $script.async = true;
      document.getElementsByTagName('head')[0].appendChild($script);
    }
  }, []);

  return <>{children}</>;
};
