import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { t } from '@lingui/macro';
import { useState } from 'react';
import { Container } from '../../container';
import { Button } from '../../button';
import { Embed } from '../../embed';
import type { EmbedCarouselProps } from './types';

export const EmbedCarousel = ({ as: Header = 'h2', title, embeds }: EmbedCarouselProps) => {
  const [slide, setSlide] = useState(0);

  const next = () => {
    const from = slide;

    if (slide >= embeds.length - 1) {
      setSlide(0);
    } else {
      setSlide(slide + 1);
    }
  };

  const previous = () => {
    const from = slide;

    if (slide === 0) {
      setSlide(embeds.length - 1);
    } else {
      setSlide(slide - 1);
    }
  };

  const active = embeds[slide];
  const single = embeds.length === 1;

  if (embeds.length <= 0) {
    return null;
  }

  return (
    <div className="bg-primary-500 py-12 md:py-18 lg:py-20">
      <Container className="items-center justify-center space-y-10">
        <Header className="text-white text-center text-3xl leading-[2.5rem] font-bold max-w-3xl md:text-5xl md:leading-[3.5rem]">{title}</Header>
        <Embed title={title} {...active} />
        {!single && (
          <div className="flex flex-row items-center justify-center space-x-4">
            <Button
              className="w-12 h-12 !p-0 text-white rounded-full hover:border-white"
              icon={<ArrowLeftIcon />}
              onClick={previous}
              iconLarge
              iconOnly
              bordered
              large
            >
              <span className="sr-only">{t`Previous`}</span>
            </Button>
            <Button
              className="w-12 h-12 !p-0 text-white rounded-full hover:border-white"
              icon={<ArrowRightIcon />}
              onClick={next}
              iconLarge
              iconOnly
              bordered
              large
            >
              <span className="sr-only">{t`Next`}</span>
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};
