import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import type { StaticImageData } from 'next/image';
import { t } from '@lingui/macro';
import Image from 'next/image';
import CompanyCommuncation from '@/images/use-cases/company-communication.jpg';
import SoftwareDevelopment from '@/images/use-cases/software-development.jpg';
import SalesAndMarketing from '@/images/use-cases/sales-and-marketing.jpg';
import CustomerSupport from '@/images/use-cases/customer-support.jpg';
import Education from '@/images/use-cases/education.jpg';
import { UseCase } from '@/enums';
import { Link } from '../../link';
import type { UseCaseListProps } from './types';

export const useCases: Record<UseCase, { title: string; image: StaticImageData; href?: string }> = {
  [UseCase.CompanyCommunication]: {
    title: t`Company Communication`,
    image: CompanyCommuncation,
  },
  [UseCase.CustomerSupport]: {
    title: t`Customer Support`,
    image: CustomerSupport,
  },
  [UseCase.Education]: {
    title: t`Education`,
    image: Education,
    href: 'education',
  },
  [UseCase.SalesAndMarketing]: {
    title: t`Sales & Marketing`,
    image: SalesAndMarketing,
    href: 'sales',
  },
  [UseCase.SoftwareDevelopment]: {
    title: t`Software Development`,
    image: SoftwareDevelopment,
  },
};

export const UseCaseList = ({ show, className }: UseCaseListProps) => {
  return (
    <div
      className={[
        'grid grid-cols-1 text-center gap-6 max-w-[90%] w-[75rem] mx-auto sm:grid-cols-2 lg:grid-cols-3',
        ...(className ? [className] : []),
      ].join(' ')}
    >
      {(show ?? Object.values(UseCase)).map(slug => (
        <Link
          className="flex flex-col basis-[0] grow shrink overflow-hidden rounded-lg"
          href={useCases[slug].href === 'sales' ? '/sales/' : useCases[slug].href ?? `/use-cases/${slug}`}
          key={slug}
        >
          <Image
            src={useCases[slug].image}
            width={useCases[slug].image.width}
            height={useCases[slug].image.height}
            alt={useCases[slug].title}
          />
          <div className="flex flex-col px-5 py-10 bg-primary-50/10">
            <p className="text-sm uppercase text-primary-600 font-medium">{t`VideoCom for`}</p>
            <p className="text-xl font-medium mt-2">
              {useCases[slug].title} <ArrowLongRightIcon className="text-primary-600 inline-block w-5 h-5" />
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
};
