import imageUrlBuilder from '@sanity/image-url';
import spacetime from 'spacetime';
import Image from 'next/image';
import { BlogCategories } from '../categories';
import { Container } from '../../container';
import { sanity } from '@/interfaces';
import { Link } from '../../link';
import type { BlogFeaturedProps } from './types';

export const BlogFeatured = ({ article }: BlogFeaturedProps) => {
  const timestamp = spacetime(new Date(article.published_at)).format('{month} {date-ordinal}, {year}');

  return (
    <Container className="items-center mb-16 space-y-4 md:space-y-0 md:space-x-8 md:flex-row">
      <Link href={`/blog/${article.slug.current}`} className="grow shrink md:basis-[0] aspect-[6/4] relative rounded overflow-hidden bg-gray-300 w-full md:w-auto">
        {article.image && (
          <Image
            src={imageUrlBuilder(sanity).image(article.image).toString()}
            alt={article.title}
            className="object-cover"
            fill
          />
        )}
      </Link>
      <div className="flex flex-col grow shrink md:basis-[0] space-y-4">
        {article.categories.length > 0 && (
          <div>
            <BlogCategories categories={article.categories} className="inline-flex" />
          </div>
        )}
        <Link href={`/blog/${article.slug.current}`} className="flex flex-col space-y-2">
          <span className="font-bold text-2xl">{article.title}</span>
          <span>{article.description}</span>
          <span className="uppercase text-slate-500 font-medium">{timestamp}</span>
        </Link>
      </div>
    </Container>
  );
};
