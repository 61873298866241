import { cloneElement } from 'react';
import type { FeatureColumnsProps } from './types';

export const FeatureColumns = ({ title, items }: FeatureColumnsProps) => {
  return (
    <div className="px-10 py-12 lg:py-20">
      <div className="mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">{title}</h2>
        <dl className="space-y-10 text-center lg:grid lg:grid-cols-3 lg:gap-20 lg:space-y-0">
          {items.map(({ title, icon, text }) => (
            <div key={title}>
              <dt>
                <div className="flex items-center justify-center rounded-md">
                  {cloneElement(icon, {
                    className: 'w-10 h-10 text-primary-500',
                    'aria-hidden': true,
                  })}
                </div>
                <p className="mt-10 text-lg font-medium leading-6 text-gray-900 lg:text-2xl">{title}</p>
              </dt>
              <dd className="mt-6 text-base text-gray-500">{text}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};
