import { Container } from '../container';
import type { FeatureProps } from './types';

export const Feature = ({
  as: Header = 'h2',
  align = 'center',
  children,
  className,
  actions,
  title,
  side,
  sideClassName,
  reverse = false,
}: FeatureProps) => {
  return (
    <div
      className={[
        'flex flex-col justify-center items-center w-full text-center py-10 md:py-20 lg:text-left',
        ...(className ? [className] : []),
      ].join(' ')}
    >
      <Container className={[align === 'center' ? 'items-center' : 'items-stretch', 'lg:!flex-row'].join(' ')}>
        <div className={['flex flex-col flex-grow flex-shrink items-center order-1 lg:items-start', reverse ? '' : 'lg:order-0'].join(' ')}>
          {title && <Header className="font-bold text-gray-900 text-xl md:text-3xl">{title}</Header>}
          <div className="prose max-w-full text-lg mt-4 md:mt-8 md:max-w-none">{children}</div>
          {actions && (
            <div className="flex flex-col items-center space-y-4 mt-10 md:flex-row md:space-x-4 md:space-y-0 md:mt-16">{actions}</div>
          )}
        </div>
        {side && (
          <div
            className={[
              'flex-grow-0 flex-shrink-0 order-0 mx-auto mb-10 max-w-sm md:max-w-none md:mb-16 lg:mb-0',
              reverse ? 'lg:mr-16' : 'lg:order-1 lg:ml-16',
              ...(sideClassName ? [sideClassName] : []),
            ].join(' ')}
          >
            {side}
          </div>
        )}
      </Container>
    </div>
  );
};
