import {
  Bars3Icon,
  XMarkIcon,
  BuildingOffice2Icon,
  ComputerDesktopIcon,
  ShoppingCartIcon,
  LifebuoyIcon,
  AcademicCapIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, cloneElement } from 'react';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button, Link } from '@/components';
import { useAnalytics } from '@/hooks';
import Logo from '@/images/logo.png';
import type { NavigationAction, NavigationItem } from './types';

export const AppHeader = () => {
  const { track } = useAnalytics();

  const navigation: NavigationItem[] = [
    {
      name: t`Use Cases`,
      children: [
        { name: t`Company Communication`, href: '/use-cases/company-communication', icon: <BuildingOffice2Icon /> },
        { name: t`Software Development`, href: '/use-cases/software-development', icon: <ComputerDesktopIcon /> },
        { name: t`Sales & Marketing`, href: '/sales', icon: <ShoppingCartIcon /> },
        { name: t`Customer Support`, href: '/use-cases/customer-support', icon: <LifebuoyIcon /> },
        { name: t`Education`, href: '/education', icon: <AcademicCapIcon /> },
      ],
    },
    {
      name: t`Products`,
      children: [
        { name: t`Presenter`, href: '/products/presenter' },
        { name: t`Capture`, href: '/products/capture' },
      ],
    },
    {
      name: t`Pricing`,
      href: '/pricing',
    },
    {
      name: t`Resources`,
      children: [
        { name: t`Blog`, href: '/blog', icon: <BookOpenIcon /> },
        { name: t`Support`, href: 'https://help.videocom.com/en/', icon: <LifebuoyIcon /> },
        { name: t`Academy`, href: '/academy', icon: <AcademicCapIcon /> },
      ],
    },
  ];

  const { locale, pathname } = useRouter();

  let signUpDestination = '';
  if (pathname === '/sales' || pathname === '/education') {
    signUpDestination = pathname.substring(1);
  }

  const actions: NavigationAction[] = [
    {
      name: t`Sign In`,
      href: `https://cloud.videocom.com/${locale}/login`,
      bordered: true,
    },
    {
      name: t`Get Started`,
      href: `https://cloud.videocom.com/${locale}/signup${signUpDestination ? '?for=' + encodeURIComponent(signUpDestination) : ''}`,
      onClick: () => {
        track('vcw.get_started.click', {
          data: { origin: 'nav' },
        });
      },
    },
  ];

  return (
    <Popover className="sticky top-0 bg-white z-50 shadow">
      {/* <Banner>
        <p className="font-medium text-white">
          <span>{t`Cyber Week - Get 40% off Now`}</span>
          <span className="block sm:ml-2 sm:inline-block">
            <Link
              href="https://cloud.videocom.com/workspace/billing?freq=yearly"
              className="text-white font-bold underline"
              self
            >
              {' '}
              {t`Claim Offer`} <span aria-hidden="true">&rarr;</span>
            </Link>
          </span>
        </p>
      </Banner> */}
      <div className="flex flex-row items-center w-full px-4 sm:px-6 h-16">
        <div className="flex flex-row w-full justify-between items-center lg:justify-start lg:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link href="/" className="flex flex-grow flex-shrink items-center">
              <span className="sr-only">VideoCom</span>
              <Image alt="VideoCom" src={Logo} width={177} height={28} priority />
            </Link>
          </div>
          <div className="-mr-2 -my-2 lg:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
              <span className="sr-only">{t`Open menu`}</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden lg:flex space-x-4 lg:space-x-10">
            {navigation.map(navigationItem =>
              navigationItem.children !== undefined ? (
                <Popover className="relative" key={navigationItem.name}>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={[
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                        ].join(' ')}
                      >
                        <span>{navigationItem.name}</span>
                        <ChevronDownIcon
                          className={[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500'].join(' ')}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xs sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {navigationItem.children?.map(item => (
                                <Popover.Button
                                  as={Link}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  href={item.href}
                                  key={item.name}
                                >
                                  {item.icon &&
                                    cloneElement(item.icon, {
                                      'className': 'flex-shrink-0 h-6 w-6 text-primary-600',
                                      'aria-hidden': 'true',
                                    })}
                                  <div className={[item.icon !== undefined ? 'ml-4' : ''].join(' ')}>
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  </div>
                                </Popover.Button>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              ) : (
                <Link
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                  href={navigationItem.href as string}
                  key={navigationItem.name}
                >
                  {navigationItem.name}
                </Link>
              ),
            )}
          </Popover.Group>
          <div className="hidden lg:flex items-center justify-end space-x-1 lg:flex-1 lg:w-0 lg:space-x-2.5">
            {actions.map(action => (
              <Button bordered={action.bordered === true} onClick={action.onClick} href={action.href} key={action.name} self>
                {action.name}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden max-h-screen">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 max-h-[calc(100vh-1rem)] flex flex-col">
            <div className="pt-5 pb-6 px-5 grow-0 shrink-0">
              <div className="flex items-center justify-between">
                <div>
                  <Image className="h-8 w-auto" alt="VideoCom" width={177} height={28} src={Logo} />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span className="sr-only">{t`Close menu`}</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="flex flex-col py-6 px-5 overflow-y-auto grow shrink">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8 order-1 lg:order-0">
                {navigation
                  .sort((a, b) => {
                    if (a.children && !b.children) {
                      return -1;
                    } else if (!a.children && b.children) {
                      return 1;
                    }

                    return 0;
                  })
                  .map(navigationItem =>
                    navigationItem.children !== undefined ? (
                      <div className="col-span-2 border-b-2 pb-4" key={navigationItem.name}>
                        <span className="font-bold text-tertiary-800">{navigationItem.name}</span>
                        <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
                          {navigationItem.children?.map(item => (
                            <Popover.Button
                              as={Link}
                              className="flex items-start text-gray-900 hover:text-gray-700"
                              href={item.href}
                              key={item.name}
                            >
                              {item.icon &&
                                cloneElement(item.icon, {
                                  'className': 'flex-shrink-0 h-5 w-5 text-primary-600',
                                  'aria-hidden': 'true',
                                })}
                              <div className={[item.icon !== undefined ? 'ml-3' : ''].join(' ')}>
                                <p className="text-base font-medium">{item.name}</p>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Popover.Button
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                        href={navigationItem.href ?? '/'}
                        key={navigationItem.name}
                        as={Link}
                      >
                        {navigationItem.name}
                      </Popover.Button>
                    ),
                  )}
              </div>
              <div className="flex flex-row items-center space-x-4 order-0 lg:order-1 mb-6 lg:mb-0 lg:mt-6">
                {actions.map(action => (
                  <Button
                    className="flex-grow flex-shrink flex-0"
                    bordered={action.bordered === true}
                    href={action.href}
                    key={action.name}
                    self
                  >
                    {action.name}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
