import { Container, Feature } from '@/components';
import { Tab } from '@headlessui/react';
import type { FeatureUseCaseListProps } from './types';
import Image from 'next/image';

export const FeatureUseCaseList = ({ items }: FeatureUseCaseListProps) => {
  return (
    <Container>
      <Tab.Group>
        <Tab.List className="-mb-px flex border-b border-gray-200">
          {items.map((item, idx) => (
            <Tab
              key={idx}
              className={({ selected }) =>
                [
                  'flex-grow py-4 px-1 text-center border-b-2 font-medium text-md',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-300 focus:outline-none focus:ring-2',
                  ...(selected
                    ? ['border-primary-500 text-primary-600']
                    : [
                      'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    ]),
                ].join(' ')
              }
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {items.map((item, idx) => (
            <Tab.Panel
              key={idx}
              unmount={false}
              className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-300 focus:outline-none focus:ring-2"
            >
              <Feature
                title={item.title}
                align="stretch"
                side={
                  item.image && (
                    <Image
                      src={item.image}
                      width={item.image.width}
                      height={item.image.height}
                      alt={item.imageAlt ?? ''}
                      className="rounded-lg h-full w-full object-cover"
                    />
                  )
                }
                sideClassName="w-full lg:w-[350px] !max-w-none"
                actions={
                  item.actions && (
                    item.actions
                  )
                }
              >
                {item.content}
              </Feature>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Container>
  );
};
