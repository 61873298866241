import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from '../link';
import type { PaginationProps } from './types';

export const Pagination = ({ baseUrl, current, total, hideIfSingle = true, categoriesFilter = [] }: PaginationProps) => {
  const hasPrevious = current - 1 > 0;
  const hasNext = current + 1 <= total;

  const link = (page: number) => {
    const params = new URLSearchParams();

    if (page !== 1) {
      params.append('page', `${page}`);
    }

    if (categoriesFilter && categoriesFilter.length > 0) {
      params.append('categories', categoriesFilter.join(','));
    }

    return `${baseUrl}/?${params.toString()}`;
  };

  if (hideIfSingle && total === 1) {
    return null;
  }

  return (
    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
      {hasPrevious && (
        <Link
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          href={link(current - 1)}
        >
          <>
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </>
        </Link>
      )}
      {[...Array(total)].map((val, index) => (
        <Link
          className={[
            'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium',
            ...(!hasPrevious && index === 0 ? ['rounded-l-md'] : []),
            ...(!hasNext && index === total - 1 ? ['rounded-r-md'] : []),
          ].join(' ')}
          activeClassName="z-10 !bg-primary-600 !border-primary-900 !text-white"
          active={current === index + 1}
          href={link(index + 1)}
          key={index}
        >
          {index + 1}
        </Link>
      ))}
      {hasNext && (
        <Link
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          href={link(current + 1)}
        >
          <>
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </>
        </Link>
      )}
    </nav>
  );
};
