import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Container } from '../container';
import { Button } from '../button';
import { Alert, Link, PricingComparisonTable, PricingPlansProps } from '@/components';
import { BillingPeriod, BillingPlan } from '@/enums';

export const PricingPlans = ({ items, hidePeriod = false, hideComparison = false }: PricingPlansProps) => {
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(BillingPeriod.Yearly);

  const buttons: Record<BillingPeriod, string> = {
    monthly: t`Monthly`,
    yearly: t`Yearly`,
  };

  return (
    <>
      {!hidePeriod && (
        <div className="relative mt-12 flex justify-center sm:mt-16">
          <h2 className="sr-only">{t`Billing Cycle`}</h2>
          <div className="flex rounded-lg bg-gray-200 p-1.5 space-x-1">
            {Object.keys(buttons).map(period => (
              <button
                type="button"
                className={[
                  'relative whitespace-nowrap rounded-md py-2 px-6 text-sm font-medium focus:z-10 focus:outline-none focus:ring-2 focus:ring-gray-100',
                  ...(billingPeriod === (period as BillingPeriod)
                    ? ['bg-primary-500', 'text-white']
                    : ['text-tertiary-900', 'hover:bg-gray-200']),
                ].join(' ')}
                onClick={e => {
                  setBillingPeriod(period as BillingPeriod);
                  e.preventDefault();
                }}
                key={period}
              >
                {buttons[period as BillingPeriod]}
              </button>
            ))}
          </div>
        </div>
      )}
      <Container className={hidePeriod ? 'mt-12' : 'mt-10'}>
        <div className="relative space-y-6 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
          <h2 className="sr-only">{t`Billing Plans`}</h2>
          {items.map(plan => (
            <div
              className={[
                plan.featured ? 'bg-primary-600 ring-2 ring-white shadow-md' : 'bg-gray-100',
                'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12',
              ].join(' ')}
              key={plan.name}
            >
              <div>
                <h3 className={[plan.featured ? 'text-white' : 'text-primary-600', 'text-base font-semibold'].join(' ')}>{plan.name}</h3>
                <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                  <div className="mt-3 flex flex-col grow shrink">
                    <div className="flex items-center">
                      <p className={[plan.featured ? 'text-white' : 'text-gray-900', 'text-4xl font-bold tracking-tight'].join(' ')}>
                        {plan.price.monthly > 0 || !!plan.price.onetime
                          ? `\$${!!plan.price.onetime ? plan.price.onetime : plan.price[billingPeriod]}`
                          : plan.price.custom ?? t`Free`}
                      </p>
                      {(plan.price.monthly > 0 || !!plan.price.onetime) && (
                        <div className="ml-4">
                          <p className={[plan.featured ? 'text-white/80' : 'text-gray-600', 'text-sm'].join(' ')}>
                            {t`USD`}{' '}
                            {plan.price.onetime === undefined && (
                              <>
                                {t`/month`} {plan.price.unit !== undefined && plan.price.unit}
                              </>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <p className={[plan.featured ? 'text-white/75' : 'text-gray-500', 'text-sm mt-2'].join(' ')}>{plan.description}</p>
                  </div>
                  <div className="mt-4">
                    <Button href={plan.button.href} secondary={plan.featured} block self>
                      {plan.button.text}
                    </Button>
                  </div>
                </div>
              </div>
              <h4 className="sr-only">{t`Features`}</h4>
              <ul
                role="list"
                className={[
                  plan.featured ? 'border-primary-200 divide-primary-200' : 'border-gray-300 divide-gray-300 divide-opacity-75',
                  'mt-7 border-t divide-y lg:border-t-0',
                ].join(' ')}
              >
                {plan.features.map(feature => (
                  <li key={feature} className="flex items-center py-3">
                    <CheckIcon
                      className={[plan.featured ? 'text-white' : 'text-primary-500', 'w-5 h-5 flex-shrink-0'].join(' ')}
                      aria-hidden="true"
                    />
                    <span className={[plan.featured ? 'text-white' : 'text-gray-600', 'ml-3 text-sm font-medium'].join(' ')}>
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Container>
      {hidePeriod ? (
        <Container className="items-center">
          <Alert className="mt-10">
            <Trans>
              Looking for our standard plans?{' '}
              <Link href="/pricing" className="font-semibold text-primary-500 hover:text-primary-300">
                View Plans
              </Link>
              .
            </Trans>
          </Alert>
        </Container>
      ) : (
        <Container className="items-center">
          <Alert className="mt-10">
            <Trans>
              Looking for a perpetual or lifetime plan?{' '}
              <Link href="/pricing/apps-pro" className="font-semibold text-primary-500 hover:text-primary-300">
                Check out Apps Pro
              </Link>
              .
            </Trans>
          </Alert>
        </Container>
      )}

      {!hideComparison && (
        <PricingComparisonTable
          plans={items}
          billingPeriod={billingPeriod}
          sections={[
            {
              name: t`Cloud Upload and Storage`,
              features: [
                {
                  name: t`Media file storage.`,
                  plans: {
                    [BillingPlan.Basic]: t`Up to 25 files or 1GB`,
                    [BillingPlan.Pro]: t`Unlimited`,
                    [BillingPlan.AppsPro]: '25GB',
                    [BillingPlan.Enterprise]: t`Unlimited`,
                  },
                },
                {
                  name: t`Direct upload from VideoCom apps.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Direct upload from browser.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
            {
              name: t`Video & Image Sharing`,
              features: [
                {
                  name: t`Private or public share links.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Request name & email to access media.`,
                  plans: {
                    [BillingPlan.Basic]: 'Limited',
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Animated GIF & HTML embedding.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Media embedding.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Private or public comments.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
            {
              name: t`Custom Branding`,
              features: [
                {
                  name: t`Customize share page.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Customize video player.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
            {
              name: t`Video Editing & Interaction`,
              features: [
                {
                  name: t`Video Inserts.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Call-to-actions.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Closed Captions.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
            {
              name: t`Media Analytics`,
              features: [
                {
                  name: t`Media and Workspace insights.`,
                  plans: {
                    [BillingPlan.Basic]: t`Limited`,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Engagement insights.`,
                  plans: {
                    [BillingPlan.Basic]: t`Limited`,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Export insights.`,
                  plans: {
                    [BillingPlan.Basic]: t`Limited`,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
            {
              name: t`Team Features`,
              features: [
                {
                  name: t`Invite people to workspace.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Central billing.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Separate team collections.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
            {
              name: t`Creator Apps (Capture & Presenter)`,
              features: [
                {
                  name: t`Screen recording.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Presentation recording (PowerPoint, Google Slides).`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Cloud recording.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Local recording.`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Recording length.`,
                  plans: {
                    [BillingPlan.Basic]: t`Limited`,
                    [BillingPlan.Pro]: t`Unlimited`,
                    [BillingPlan.AppsPro]: t`Unlimited`,
                    [BillingPlan.Enterprise]: t`Unlimited`,
                  },
                },
                {
                  name: t`Video resolution.`,
                  plans: {
                    [BillingPlan.Basic]: t`Presenter 720p. Capture Unlimited.`,
                    [BillingPlan.Pro]: t`Presenter 720p. Capture Unlimited.`,
                    [BillingPlan.AppsPro]: t`Presenter 720p. Capture Unlimited.`,
                    [BillingPlan.Enterprise]: t`Presenter 720p. Capture Unlimited.`,
                  },
                },
                {
                  name: t`Undo/redo recording.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Drawing & annotation tools.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Microphone & desktop audio.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Microphone noise suppression.`,
                  plans: {
                    [BillingPlan.Basic]: true,
                    [BillingPlan.Pro]: true,
                    [BillingPlan.AppsPro]: true,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Presentation notes.`,
                  plans: {
                    [BillingPlan.Basic]: t`Only in Presenter`,
                    [BillingPlan.Pro]: t`Only in Presenter`,
                    [BillingPlan.AppsPro]: t`Only in Presenter`,
                    [BillingPlan.Enterprise]: t`Only in Presenter`,
                  },
                },
              ],
            },
            {
              name: t`Enterprise Administration`,
              features: [
                {
                  name: t`SSO (SAML).`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: false,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
                {
                  name: t`Directory & provisioning (SCIM).`,
                  plans: {
                    [BillingPlan.Basic]: false,
                    [BillingPlan.Pro]: false,
                    [BillingPlan.AppsPro]: false,
                    [BillingPlan.Enterprise]: true,
                  },
                },
              ],
            },
          ]}
        />
      )}
    </>
  );
};
