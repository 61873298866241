import { t } from '@lingui/macro';
import Image from 'next/image';
import Flickity from 'react-flickity-component';
import type { TestimonialsSliderProps } from '@/components';

export const TestimonialsSlider = ({ items }: TestimonialsSliderProps) => {
  return (
    <section className="overflow-hidden py-12 md:py-20 lg:py-24">
      <div className="relative mx-auto max-w-6xl px-6 sm:px-10">
        <svg
          className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <Flickity
          className="outline-none"
          options={{
            wrapAround: true,
            initialIndex: 1,
          }}
        >
          {items.map((testimonial) => (
            <div
              className="mx-12 aria-hidden:opacity-0 transition-opacity opacity-100"
              key={testimonial.author}
            >
              <blockquote className="mt-10">
                <div className="mx-auto max-w-3xl text-center text-xl md:text-2xl font-medium leading-9 text-gray-900">
                  <p>&ldquo;{testimonial.quote}&rdquo;</p>
                </div>
                <footer className="mt-8">
                  <div className="md:flex md:items-center md:justify-center">
                    <div className="md:flex-shrink-0">
                      {testimonial.image && (
                        <Image
                          className="mx-auto h-10 w-10 rounded-full object-cover"
                          src={testimonial.image}
                          alt={t`${testimonial.author}`}
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div className="text-base font-medium">{testimonial.author}</div>

                      {testimonial.role && (
                        <>
                          <svg
                            className="mx-1 hidden h-5 w-5 text-indigo-600 md:block"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path d="M11 0h3L9 20H6l5-20z" />
                          </svg>

                          <div className="text-base font-medium text-gray-500">
                            {testimonial.role}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          ))}
        </Flickity>
      </div>
    </section>
  );
};
