import type { NextSeoProps } from 'next-seo';

export function useOG(title: string) {
  const url = `${process.env.NEXT_PUBLIC_URL}/api/og/?title=${encodeURIComponent(title)}`;

  const openGraph: NonNullable<NextSeoProps['openGraph']> = {
    images: [{
      url,
      alt: title,
    }],
  };

  return { url, openGraph };
}
