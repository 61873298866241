import * as React from 'react';
import type { ContainerProps } from './types';

export const Container = ({ row = false, large = false, noPaddingMobile = false, className, children }: ContainerProps) => {
  return (
    <div
      className={[
        row ? 'flex-row' : 'flex-col',
        large ? 'max-w-8xl' : 'max-w-6xl',
        'flex',
        'flex-grow',
        'flex-shrink',
        'w-full',
        'mx-auto',
        ...(noPaddingMobile ? [] : ['px-6']),
        'sm:px-10',
        ...(className ? [className] : []),
      ].join(' ')}
    >{children}</div>
  );
};
