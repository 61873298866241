import type { apiObject } from 'rudder-sdk-js';
import { useRudderstack } from '../rudderstack';

export function useAnalytics() {
  const { rudderstack } = useRudderstack();

  const track = (event: string, properties: apiObject = {}) => {
    rudderstack()?.track(event, properties);
    // window.gtag?.('event', event, properties);
  };

  return { track };
}
