import { createContext, useEffect } from 'react';
import { nanoid } from 'nanoid';
import type { RudderstackProviderProps, RudderstackProviderState } from './types';

export const RudderstackContext = createContext<RudderstackProviderState | null>(null);

export const RudderstackProvider = ({ children }: RudderstackProviderProps) => {
  useEffect(() => {
    async function run() {
      window.rudderanalytics = await import('rudder-sdk-js');

      const $script = document.createElement('script');
      $script.setAttribute('data-domain-script', process.env.NEXT_PUBLIC_ONETRUST_KEY as string);
      $script.async = true;
      $script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      document.getElementsByTagName('head')[0].appendChild($script);

      window.OptanonWrapper = () => {
        if (window.OnetrustActiveGroups?.indexOf('C0007') !== -1) {
          window.rudderanalytics?.load(
            process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY as string,
            process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL as string,
            {
              configUrl: process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL as string,
              integrations: { All: true },
              useBeacon: false,
            },
          );

          if (`${window.rudderanalytics?.getAnonymousId()}`.length > 20) {
            window.rudderanalytics?.setAnonymousId(nanoid(14));
          }

          // Track initial page load as a page view.
          // window.rudderanalytics?.ready(() => {
          window.rudderanalytics?.page();
          // });
        }
      };
    }

    if (process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY && process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY.length) {
      run();
    }
  }, []);

  const client = () => (globalThis as any)?.rudderanalytics ?? undefined;

  return <RudderstackContext.Provider value={{ rudderstack: client }}>{children}</RudderstackContext.Provider>;
};
