import { useEffect, useRef, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { t } from '@lingui/macro';
import { UseCaseList } from './list';
import type { UseCaseProps } from './types';

export const UseCase = ({ video, items, more, className }: UseCaseProps) => {
  const [player, setPlayer] = useState<any | null>(null);
  const iframe = useRef<HTMLIFrameElement>(null);
  const [active, setActive] = useState(0);

  useEffect(() => {
    async function run() {
      const { Player } = (await import('player.js')).default;
      const player = new Player(iframe.current);
      player.on('ready', () => {
        player.mute();
        player.play();
      });
      setPlayer(player);
    }
    run();
  }, []);

  return (
    <div className={['flex flex-col w-[100rem] max-w-[90%] mx-auto space-y-20', ...(className ? [className] : [])].join(' ')}>
      <div className="w-full flex flex-col bg-primary-100 p-5 rounded-xl sm:p-10 lg:p-20 lg:flex-row">
        <dl className="divide-y divide-primary-300 order-2 grow shrink lg:pr-10 lg:order-1">
          {items.map((item, index) => (
            <Disclosure
              as="div"
              key={index}
              className="py-5"
              defaultOpen={!index}
            >
              {({ open, close }) => {
                if (open && active !== index) {
                  close();
                }

                return (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button
                        className="flex w-full items-start justify-between text-left text-gray-400"
                        onClick={() => {
                          setActive(index);
                          player?.setCurrentTime(item.timestamp);
                          player?.unmute();
                          player?.play();
                        }}
                      >
                        <span className="font-medium text-gray-900">{item.title}</span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-sm text-gray-600 lg:text-base">{item.desc}</p>
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
          ))}
        </dl>
        <div className="w-full shrink-0 grow-0 order-1 lg:order-2 lg:max-w-[60%]">
          <div className="aspect-video rounded-lg overflow-hidden">
            <iframe
              ref={iframe}
              width="100%"
              height="100%"
              src={video}
              frameBorder="0"
              loading="lazy"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title={t`Use Case Video`}
              allowFullScreen
            />
          </div>
        </div>
      </div>
      {more && (
        <div className="text-center">
          <h2 id="more" className="font-bold text-gray-900 text-2xl md:text-4xl">{t`More use cases`}</h2>
          <UseCaseList className="mt-10" show={more} />
        </div>
      )}
    </div>
  );
};
