import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Background from '@/images/cta-background.png';
import { Container } from '../container';
import type { CTAProps } from './types';
import { useAnalytics } from '@/hooks';
import { Button } from '../button';

export const CTA = ({
  as: Header = 'h2',
  title = t`Level up your video with us.`,
  items,
  className,
}: CTAProps) => {
  const { locale } = useRouter();
  const { track } = useAnalytics();

  if (!items) {
    items = [{
      text: t`Create and share screen captures or high quality video presentations with ease.`,
      button: (
        <Button
          href={`https://cloud.videocom.com/${locale}/signup`}
          icon={<ArrowRightIcon />}
          iconPosition="right"
          large
          self
          onClick={() => {
            track('vcw.get_started.click', {
              data: { origin: 'cta' },
            });
          }}
        >{t`Get Started`}</Button>
      ),
    }];
  }

  return (
    <div className={['flex flex-col items-center justify-center w-full text-center relative py-12 overflow-hidden', ...(className ? [className] : [])].join(' ')}>
      <Container className="flex flex-col items-center justify-center relative z-20 space-y-8">
        <Header className="font-bold text-gray-900 text-2xl max-w-[90%] md:max-w-[75%] md:text-4xl">{title}</Header>
        <div className="flex flex-col space-y-10 sm:space-y-0 sm:flex-row sm:flex-wrap sm:space-x-10">
          {items.map(({ text, button }, index) => (
            <div className="flex flex-col" key={index}>
              <p className="text-md text-black sm:text-gray-500 max-w-md md:text-lg">{text}</p>
              <div className="mt-4 sm:mt-8">{button}</div>
            </div>
          ))}
        </div>
      </Container>
      <div className="absolute z-10 max-w-full top-[45%]">
        <Image
          alt="Level up background"
          src={Background}
          width={688}
          height={508}
        />
      </div>
    </div>
  );
};
