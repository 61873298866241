import * as React from 'react';
import { Loader } from '../loader';
import { Link } from '../link';
import type { ButtonProps } from './types';

export const Button = ({
  type = 'button',
  loading = false,
  block = false,
  bordered = false,
  flat = false,
  danger = false,
  large = false,
  secondary = false,
  disabled = false,
  href,
  self = false,
  icon,
  iconOnly = false,
  iconLarge = false,
  iconPosition = 'left',
  className,
  children,
  onClick,
}: ButtonProps) => {
  const Component = href ? Link : 'button';

  const cls = [
    ...(block ? [
      'flex',
      'w-full',
    ] : [
      'inline-flex',
    ]),
    'flex-row',
    'justify-center',
    'items-center',
    ...(large ? [
      'px-8',
      'py-2',
      'text-base',
      'md:py-3',
      'md:px-8',
      ...(bordered || flat ? [] : ['shadow']),
    ] : [
      'px-4',
      'py-2',
      'text-sm',
      ...(bordered || flat ? [] : ['shadow-sm']),
    ]),
    ...(bordered ? [
      'text-primary-600',
      'bg-transparent',
      'border-gray-400',
      'border-2',
      'hover:border-primary-600',
    ] : (flat ? [
      'text-primary-600',
      'bg-transparent',
      'hover:border-primary-600',
    ] : (danger ? [
      'text-white',
      'bg-red-700',
      'hover:bg-red-800',
    ] : (secondary ? [
      'text-gray-900',
      'bg-secondary-300',
      'hover:bg-secondary-200',
    ] : [
      'text-white',
      'bg-primary-600',
      'hover:bg-primary-500',
    ])))),
    'box-border',
    'rounded-full',
    'font-medium',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-offset-2',
    'focus:ring-offset-white',
    ...(danger ? ['focus:ring-red-700'] : ['focus:ring-primary-500']),
    ...(disabled ? ['!bg-gray-500 hover:!bg-gray-500'] : []),
    ...(className ? [className] : []),
  ].join(' ');

  return (
    // @ts-ignore
    <Component
      className={cls}
      {...(href !== undefined ? {
        href,
        self,
        onClick,
      } : {
        type,
        disabled,
        onClick,
      })}
    >
      {(icon && !loading && iconPosition === 'left') && React.cloneElement(icon, {
        className: [
          ...(iconLarge ? ['w-6', 'h-6'] : ['w-4', 'h-4']),
          ...(iconOnly ? [] : ['mr-2']),
        ].join(' '),
        'aria-hidden': true,
      })}
      {loading && (
        <Loader
          className={[
            ...(iconLarge ? ['w-6', 'h-6', 'border-4'] : ['w-4', 'h-4', 'border-2']),
            ...(iconOnly ? [] : ['mr-2']),
          ].join(' ')}
          customSize
          light
        />
      )}
      {children}
      {(icon && iconPosition === 'right') && React.cloneElement(icon, {
        className: [
          ...(iconLarge ? ['w-6', 'h-6'] : ['w-4', 'h-4']),
          ...(iconOnly ? [] : ['ml-2']),
        ].join(' '),
        'aria-hidden': true,
      })}
    </Component>
  );
};
