import { useRouter } from 'next/router';
import BaseLink from 'next/link';
import * as React from 'react';
import type { LinkProps } from './types';

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link ({ id, href, className, activeClassName, exactActiveClassName, active, locale, children, self = false, onClick }, ref) {
  const router = useRouter();

  const external = href.indexOf('http') === 0;
  const isActive = active === true || (!external && router.pathname.indexOf(href) === 0 && active === undefined);
  const exactActive = active === true || (!external && router.pathname === href && active === undefined);

  const cls = [
    ...(className ? className.split(' ') : []),
    ...((isActive && activeClassName) ? activeClassName.split(' ') : []),
    ...((exactActive && exactActiveClassName) ? exactActiveClassName.split(' ') : []),
  ];

  return (
    <BaseLink
      id={id}
      ref={ref}
      href={href}
      locale={locale}
      className={cls.join(' ')}
      onClick={onClick}
      scroll
      {...(external && {
        target: self ? '_self' : '_blank',
        rel: self ? undefined : 'noopener noreferrer',
      })}
    >
      {children}
    </BaseLink>
  );
});
