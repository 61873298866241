import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { getCookieValue } from '@/experiments';
import { Cookie } from '@/enums';

export function useExperiment() {
  useEffect(() => {
    // Fetch the current experiment & variant.
    const cookie = Cookies.get(Cookie.Experiment);

    if (cookie) {
      const { experiment } = getCookieValue(cookie);

      window.gtag?.('event', 'experiment_impression', {
        experiment_id: experiment,
        variant_id: cookie,
        send_to: 'G-T2B5JMWE0Q', // TODO: env var
      });
    }
  }, []);
}
