import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { cloneElement, Fragment } from 'react';
import type { I18nSwitcherProps } from './types';
import type { Locale } from '@/types';
import { Link } from '../../link';

export const languages = {
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  ja: '日本語',
};

export const I18nSwitcher = ({ className }: I18nSwitcherProps) => {
  const { locale = 'en', locales = [], asPath } = useRouter();

  const active = languages[locale as Locale] ?? locale;

  return (
    <Menu as="div" className={['relative inline-block', ...(className ? [className] : [])].join(' ')}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-primary-400 bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
              {active}
              {cloneElement(open ? <ChevronDownIcon /> : <ChevronUpIcon />, {
                className: 'ml-2 -mr-1 h-5 w-5 text-primary-200 hover:text-primary-100',
                'aria-hidden': true,
              })}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 bottom-full mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-primary-500 ring-opacity-5 focus:outline-none">
              <div className="px-1 py-1">
                {locales.map(code => (
                  <Menu.Item key={code}>
                    {({ active }) => (
                      <Link
                        href={asPath}
                        locale={code as Locale}
                        className={`${active ? 'bg-primary-500 text-white' : 'text-gray-900'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >{languages[code as Locale]}</Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
