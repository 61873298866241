import type { StaticImageData } from 'next/image';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { Container } from '../../container';
import { Embed } from '../../embed';
import type { PageATFProps } from './types';

export const PageATF = ({
  as = 'h1',
  className,
  title,
  titleText,
  tagline,
  button,
  embed,
  image,
  noVideoCom = false,
  blueBackground = false,
  switchTitle = false,
}: PageATFProps) => {
  const Title = switchTitle ? 'p' : as;
  const Tagline = switchTitle ? as : 'p';

  return (
    <div
      className={[
        'flex flex-col grow-0 shrink-0 relative items-center justify-center text-center w-full pt-14 pb-10 lg:pt-24 lg:pb-0',
        ...(blueBackground ? ['bg-primary-600'] : ['bg-gray-800']),
        ...(className ? [className] : []),
      ].join(' ')}
    >
      <Container className="items-center relative z-20">
        {!noVideoCom && <p className="uppercase font-semibold text-primary-50">{t`VideoCom`}</p>}
        <Title className="font-black text-white mt-1 text-3xl md:text-4xl lg:text-5xl">{title}</Title>
        <Tagline className="text-gray-300 font-semibold text-lg mt-4 lg:text-xl">{tagline}</Tagline>
        {button && <div className="mt-8">{button}</div>}
        {embed && (
          <Embed title={typeof title === 'string' ? title : titleText ?? ''} className="max-w-4xl mt-14 lg:-mb-10 lg:mt-24" {...embed} />
        )}
        {image && (
          <div className="flex items-center justify-center w-full">
            <div className="text-[0] rounded-lg overflow-hidden w-full h-auto relative max-w-4xl mt-14 lg:-mb-10 lg:mt-20">
              {!!(image as any).src ? (
                <Image
                  alt={typeof title === 'string' ? title : titleText ?? ''}
                  src={image as StaticImageData}
                  width={(image as StaticImageData).width}
                  height={(image as StaticImageData).height}
                />
              ) : (
                <>{image}</>
              )}
            </div>
          </div>
        )}
      </Container>
      {blueBackground && (
        <div className="absolute overflow-hidden w-full h-full top-0 left-0 z-10">
          <div className="absolute left-[67.5%] right-[-13.06%] top-[-5.8%] bottom-[-18.21%] rounded-full bg-primary-400" />
          <div className="absolute right-[67.5%] left-[-13.06%] top-[-5.8%] bottom-[-18.21%] rounded-full bg-primary-500" />
        </div>
      )}
    </div>
  );
};
