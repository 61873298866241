import { useRouter } from 'next/router';
import { i18n } from '@lingui/core';
import { useEffect } from 'react';

/**
 * Just including this hacky shit to test out CrowdIn
 * In-Context translating for web.
 * - Tom
 */
export const I18nInContext = () => {
  const router = useRouter();

  useEffect(() => {
    async function run() {
      let status: boolean | null = null;

      if (router.asPath.includes('in-context=true')) {
        status = true;
      } else if (router.asPath.includes('in-context=false')) {
        status = false;
      }

      if (status === true && localStorage?.getItem('crowdin-incontext') !== 'true') {
        localStorage.setItem('crowdin-incontext', 'true');
      } else if (status === false && localStorage?.getItem('crowdin-incontext') === 'true') {
        localStorage.removeItem('crowdin-incontext');
      }

      if (localStorage?.getItem('crowdin-incontext') === 'true') {
        // Load the in-context meta language so it knows Message IDs.
        const { messages } = await import('@/locales/pa/messages.po');
        i18n.load('pa', messages);
        i18n.activate('pa');

        // Tell CrowdIn which project to authenticate for.
        window._jipt = window._jipt ?? [];
        window._jipt.push(['project', 'videocom-site']);

        // Add the script to the <head>.
        const $script = document.createElement('script');
        $script.type = 'text/javascript';
        $script.src = 'https://cdn.crowdin.com/jipt/jipt.js';
        $script.async = true;
        document.head.prepend($script);
      }
    }
    run();
  }, []);

  return null;
};
