import { I18nProvider as LinguiProvider } from '@lingui/react';
import { useEffect, useRef } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { i18n } from '@lingui/core';
import Script from 'next/script';
import Head from 'next/head';
import { CrispProvider, PlatformProvider, RudderstackProvider, I18nProvider } from '@/providers';
import { activateLocale, initTranslation } from '@/locales';
import { Progress, I18nInContext } from '@/components';
import type { Locale, PageProps } from '@/types';
import { layouts } from '@/layouts';

import 'react-before-after-slider-component/dist/build.css';
import 'nprogress/nprogress.css';
import '@/styles/inter.css';
import '@/styles/globals.css';

initTranslation(i18n);

export default function App({ Component, pageProps }: AppProps<PageProps>) {
  const firstRender = useRef(true);
  const router = useRouter();

  // Base URL for meta tags.
  const baseUrl = 'https://videocom.com';

  // Handle the Layout to use.
  const useLayout = (Component as any).layout as keyof typeof layouts | undefined;
  const Layout = useLayout ? layouts[useLayout] : layouts.default;

  // Handle the locale loading.
  const localePath = (mapLocale: Locale | string) => (mapLocale !== defaultLocale ? `/${mapLocale}` : '');
  const defaultLocale = (router.defaultLocale ?? 'en') as Locale;
  const locales = router.locales ?? [defaultLocale];
  const locale = (router.locale ?? defaultLocale) as Locale;

  // Load the locale if data is present.
  if (pageProps.translations && firstRender.current) {
    i18n.load(locale, pageProps.translations);
    i18n.activate(locale);
    firstRender.current = false;
  }

  const pageUrl = new URL(`${localePath(locale)}${router.asPath}`, baseUrl);
  pageUrl.hash = '';

  useEffect(() => {
    if (locale !== i18n.locale) {
      activateLocale(locale);
    }
  }, [locale]);

  return (
    <RudderstackProvider>
      <CrispProvider>
        <PlatformProvider>
          <I18nProvider>
            <LinguiProvider i18n={i18n}>
              <DefaultSeo
                titleTemplate="%s | VideoCom"
                defaultTitle="VideoCom"
                canonical={pageUrl.toString()}
                languageAlternates={locales.map(mapLocale => {
                  const url = new URL(`${localePath(mapLocale)}${router.asPath}`, baseUrl);
                  url.hash = '';

                  return {
                    hrefLang: mapLocale,
                    href: url.toString(),
                  };
                })}
                openGraph={{
                  type: 'website',
                  url: pageUrl.toString(),
                  site_name: 'VideoCom',
                  locale,
                }}
              />
              <Script
                id="gtag-shim"
                dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || []; window.gtag = window.gtag || function(){dataLayer.push(arguments)};`,
                }}
              />
              <Head>
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                />
              </Head>
              <Progress />
              <I18nInContext />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </LinguiProvider>
          </I18nProvider>
        </PlatformProvider>
      </CrispProvider>
    </RudderstackProvider>
  );
}
