import type { QuotesProps } from './types';
import Image from 'next/image';
import { t } from '@lingui/macro';

export const Quotes = ({ items, className }: QuotesProps) => {
  return (
    <div className={['mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', ...(className ? [className] : [])].join(' ')
    }>
      <div className="grid gap-12 lg:gap-6 lg:grid-cols-3 grid-cols-1 mt-20 mb-4">
        {items.map((item) => (
          <figure className="max-w-screen-md mx-auto text-center" key={item.name}>
            <div className="text-primary-500 flex justify-center mb-8">
              <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.607 0.970215H5.63766C2.52422 0.970215 0 3.49443 0 6.60787V12.5772C0 15.6907 2.52422 18.2148 5.63766 18.2148H6.2282C6.72336 18.2148 7.18719 18.4592 7.46805 18.8681C7.74766 19.277 7.80906 19.7976 7.63008 20.2596L4.26312 28.985C4.08414 29.4475 4.14555 29.9681 4.42516 30.3764C4.70602 30.7853 5.16984 31.0297 5.665 31.0297H9.62891C10.188 31.0297 10.7016 30.7187 10.9602 30.2236L15.7459 21.0806C16.731 19.1986 17.2458 17.1055 17.2458 14.9812V6.60779C17.2459 3.49443 14.7205 0.970215 11.607 0.970215Z" fill="currentColor" />
                <path d="M34.3624 0.970215H28.3929C25.2794 0.970215 22.7554 3.49443 22.7554 6.60787V12.5772C22.7554 15.6907 25.2795 18.2148 28.3929 18.2148H28.9822C29.4773 18.2148 29.9424 18.4592 30.222 18.8681C30.5029 19.277 30.563 19.7976 30.3854 20.2596L27.0172 28.9851C26.8382 29.4476 26.8996 29.9682 27.1805 30.3765C27.4601 30.7854 27.9239 31.0297 28.4204 31.0297H32.383C32.9422 31.0297 33.4557 30.7187 33.7157 30.2237L38.5001 21.0807C39.4851 19.1986 39.9999 17.1056 39.9999 14.9812V6.60779C39.9999 3.49443 37.4758 0.970215 34.3624 0.970215Z" fill="currentcolor" fillOpacity="0.3" />
              </svg>
            </div>

            <blockquote>
              <p className="text-center text-lg md:max-w-[40ch] md:mx-auto font-medium text-gray-900">
                &quot;{item.quote}&quot;
              </p>
            </blockquote>
            <figcaption className="flex flex-col items-center justify-center mt-6 space-x-3">
              <Image
                className="w-12 h-12 rounded-full object-cover"
                src={item.avatarImage}
                alt={item.avatarAlt ? item.avatarAlt : t`Portait of ${item.name}`}
              />
              <cite className="not-italic font-medium text-gray-900 py-1">
                {item.name}
              </cite>
              <cite className="not-italic text-sm text-gray-400">
                {item.company}
              </cite>
            </figcaption>
          </figure>
        ))}
      </div>
    </div>
  );
};
