import { Apple, Windows, Linux, Android } from '@ricons/fa';
import { cloneElement } from 'react';
import { useRouter } from 'next/router';
import { t } from '@lingui/macro';
import { Platform, Product } from '@/enums';
import { usePlatform, useAnalytics, useRudderstack } from '@/hooks';
import { Button } from '../button';
import type { DownloadButtonProps } from './types';

export const productLinks = {
  [Product.Presenter]: {
    [Platform.macOS]: '/api/download/presenter/macos',
    [Platform.Windows]: '/api/download/presenter/windows',
    [Platform.Linux]: '',
    [Platform.Android]: '',
    [Platform.iOS]: '',
  },
  [Product.Capture]: {
    [Platform.macOS]: '/api/download/capture/macos',
    [Platform.Windows]: '/api/download/capture/windows',
    [Platform.Linux]: '',
    [Platform.Android]: '',
    [Platform.iOS]: '',
  },
};

export const platformIcons = {
  [Platform.macOS]: () => <Apple />,
  [Platform.Windows]: () => <Windows />,
  [Platform.Linux]: () => <Linux />,
  [Platform.iOS]: () => <Apple />,
  [Platform.Android]: () => <Android />,
};

export const DownloadButton = ({ product, children, ...props }: DownloadButtonProps) => {
  const { rudderstack } = useRudderstack();
  const { platform } = usePlatform();
  const { track } = useAnalytics();

  let href = new URL(productLinks[product][platform], process.env.NEXT_PUBLIC_URL);
  const supported = productLinks[product][platform].length > 0;
  const anonId = rudderstack()?.getAnonymousId();

  const { locale } = useRouter();

  if (anonId) {
    href.searchParams.set('id', anonId);
  }

  if (!supported) {
    href = new URL(`https://cloud.videocom.com/${locale}/signup`);
  }

  return (
    <Button
      {...props}
      href={href.toString()}
      onClick={() => {
        if (supported) {
          track('vcw.app.download', {
            data: {
              app: product.toLowerCase(),
              platform: platform.toLowerCase(),
            },
          });
        } else {
          track('vcw.get_started.click', {
            data: { origin: 'download' },
          });
        }
      }}
    >
      {supported &&
        cloneElement(platformIcons[platform](), {
          className: 'w-6 h-6 mr-2',
        })}
      {supported ? children : t`Get Started`}
    </Button>
  );
};
