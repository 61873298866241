import { Facebook, Twitter, Linkedin, Envelope, Youtube } from '@ricons/fa';
import { cloneElement, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import Image from 'next/image';
import { I18nSwitcher } from '../../i18n';
import Logo from '@/images/glyph.svg';
import { Link } from '../../link';
import type { NavigationItem } from './types';
import { useI18n } from '@/hooks';

export const AppFooter = () => {
  // Force re-render when locale changes. Hacky shit.
  const [count, setCount] = useState(0);
  const { current } = useI18n();

  useEffect(() => {
    setCount(count + 1);
  }, [current]);

  const navigation: NavigationItem[] = [
    {
      name: t`Company`,
      children: [
        { name: t`About Us`, href: '/about-us' },
        { name: t`Contact Sales`, href: '#!', id: 'contact-us' },
        { name: t`For Nonprofits`, href: '/for-nonprofits/' },
        { name: t`For Education`, href: '/for-education' },

      ],
    },
    {
      name: t`Resources`,
      children: [
        { name: t`Blog`, href: '/blog' },
        { name: t`Support`, href: 'https://help.videocom.com/en/' },
        { name: t`Affiliates`, href: '/affiliates' },
        { name: t`Use Cases`, href: '/use-cases' },
        { name: t`Webex Integration`, href: '/blog/webex-integration/' },
        { name: t`Academy`, href: '/academy/' },
      ],
    },
    {
      name: t`Legal`,
      children: [
        { name: t`Terms & Conditions`, href: '/terms-and-conditions' },
        { name: t`Cookie Preferences`, href: '#!', onClick: () => window.OneTrust?.ToggleInfoDisplay() },
        { name: t`Privacy Policy`, href: '/privacy' },
        { name: t`Security`, href: '/security' },
      ],
    },
    {
      name: t`Blog`,
      children: [
        { name: t`Webex`, href: '/blog/videocom-presenter-camera-webex-meetings/' },
        { name: t`Webinar`, href: '/blog/creating-best-first-live-sales-webinar/' },
        { name: t`Sales Pitch`, href: '/blog/perks-prerecording-sales-pitch/' },
        { name: t`Asynchronous`, href: '/blog/balance-synchronous-asynchronous-communication/' },
        { name: t`Google Meet`, href: '/blog/present-google-meet-videocom-presenter/' },
        { name: t`Capture`, href: '/blog/screen-capture-sales-advantage/' },
      ],
    },
  ];

  const social = [
    { name: t`YouTube`, href: 'https://www.youtube.com/@VideoComHQ', icon: <Youtube /> },
    { name: t`Facebook`, href: 'https://www.facebook.com/VideoComHQ', icon: <Facebook /> },
    { name: t`Twitter`, href: 'https://www.twitter.com/VideoComHQ', icon: <Twitter /> },
    { name: t`Linkedin`, href: 'https://www.linkedin.com/company/VideoComHQ', icon: <Linkedin /> },
    { name: t`Contact Us`, href: 'mailto:contact@videocom.com', icon: <Envelope /> },
  ];

  const reviewSites = [
    {
      name: 'Capterra',
      href: 'https://www.capterra.com/reviews/281133/VideoCom?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge',
      imageUrl: 'https://assets.capterra.com/badge/4c17a4650413298c1ec9941fdf49ff85.svg?v=2228668&p=281133',
    },
    {
      name: 'Software Advice',
      href: 'https://www.softwareadvice.com/video-conferencing/videocom-profile',
      imageUrl: 'https://badges.softwareadvice.com/reviews/b9e22e61-68f1-4d9e-b9f0-0d46e2352259',
    },
  ];

  return (
    <footer className="bg-gray-900 relative z-40" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">{t`Footer`}</h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="flex flex-col space-y-8 order-1 mt-12 lg:mt-0 lg:order-0 lg:col-span-1">
            <div className="flex-grow flex-shrink">
              <Image className="h-12" alt="VideoCom" src={Logo} />
            </div>
            <div className="flex flex-wrap items-center space-x-6 flex-shrink-0 flex-grow-0 gap-y-4">
              <I18nSwitcher />
              <div className="flex flex-wrap space-x-6 flex-shrink-0 flex-grow-0">
                {social.map(item => (
                  <Link key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                    <span className="sr-only">{item.name}</span>
                    {cloneElement(item.icon, {
                      'className': 'h-5 w-5',
                      'aria-hidden': 'true',
                    })}
                  </Link>
                ))}
              </div>
            </div>
            {/* Review Badges */}
            <div className="flex flex-shrink-0 flex-grow-0 flex-row flex-wrap gap-4">
              {reviewSites.map((site, index) => (
                <Link href={site.href} key={site.name} className={index === 0 ? 'ml-[-13px]' : ''}>
                  <img src={site.imageUrl} alt={site.name} className="h-14 w-auto" loading="lazy" />
                </Link>
              ))}
            </div>
            <div>
              <p className="text-base text-gray-400">{t`© 2023 VideoCom.com Inc. All rights reserved.`}</p>
              <p className="mt-2 text-base text-gray-400">{t`8 The Green STE R, Dover, DE 19901, USA.`}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 order-0 lg:order-1 lg:col-span-2">
            <div className="w-full grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:gap-8 lg:max-w-2xl lg:ml-auto">
              {navigation.map(col => (
                <div key={col.name}>
                  <h3 className="text-sm font-semibold text-white tracking-wider uppercase">{col.name}</h3>
                  <ul role="list" className="space-y-3 mt-4 lg:space-y-4 lg:mt-8">
                    {col.children.map(item => (
                      <li key={item.name}>
                        <Link className="text-base text-gray-400 hover:text-gray-300" onClick={item.onClick} href={item.href} id={item.id}>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
