import { useCallback, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import Image from 'next/image';
import { EmbedPlay } from './play';
import type { EmbedProps } from './types';

export const Embed = ({ url, title, className, poster, isLinusCampaign, autoPlay = false }: EmbedProps) => {
  const [shouldAutoPlay, setShouldAutoPlay] = useState(autoPlay);
  const [player, setPlayer] = useState<any | null>(null);
  const [visible, setVisible] = useState(!poster);
  const iframe = useRef<HTMLIFrameElement>(null);

  const setFrameRef = useCallback(async (node: HTMLIFrameElement) => {
    if (iframe.current) {
      setPlayer(null);
    }

    if (node) {
      const { Player } = (await import('player.js')).default;
      const instance = new Player(node);
      setPlayer(instance);
    }

    // @ts-ignore
    iframe.current = node;
  }, []);

  useEffect(() => {
    if (player) {
      player.on('ready', () => {
        if (shouldAutoPlay) {
          player.mute();
          player.play();
        }
      });
    }
  }, [player]);

  return (
    <div
      className={[
        isLinusCampaign
          ? 'rounded-lg overflow-hidden aspect-video w-full h-auto bg-gray-100 border border-white border-opacity-50 bg-opacity-0 p-5'
          : 'rounded-lg overflow-hidden aspect-video w-full h-auto shadow-lg bg-gray-100',
        ...(className ? [className] : []),
      ].join(' ')}
    >
      {!!poster && !visible && (
        <div className="flex items-center justify-center w-full h-full relative">
          <Image src={poster} alt={title} fill />
          <EmbedPlay
            className="relative z-10"
            onClick={e => {
              setShouldAutoPlay(true);
              setVisible(true);
              e.preventDefault();
            }}
          >{t`Play Video`}</EmbedPlay>
        </div>
      )}
      {visible && (
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          className="w-full h-full aspect-video"
          ref={setFrameRef}
          allowFullScreen
          frameBorder="0"
          loading="lazy"
          title={title}
          src={url}
        />
      )}
    </div>
  );
};
