import { useRouter } from 'next/router';
import { createContext } from 'react';
import type { Locale } from '@/types';
import type { I18nProviderProps, I18nProviderState } from './types';

export const I18nContext = createContext<I18nProviderState | null>(null);

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const router = useRouter();

  const defaultLocale = (router.defaultLocale ?? 'en') as Locale;
  const current = (router.locale ?? defaultLocale) as Locale;

  const pick: I18nProviderState['pick'] = (base, options) => {
    if ((options as any)[current]) {
      return (options as any)[current];
    }

    return base;
  };

  return <I18nContext.Provider value={{ current, pick }}>{children}</I18nContext.Provider>;
};
