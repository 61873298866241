import { PlayIcon } from '@heroicons/react/20/solid';
import { Button } from '../../button';
import type { EmbedPlayProps } from './types';

export const EmbedPlay = ({ children, ...props }: EmbedPlayProps) => {
  return (
    <Button
      icon={<PlayIcon />}
      large
      {...props}
    >{children}</Button>
  );
};
