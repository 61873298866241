/**
 * TODO: graphql-code-gen and handle this GraphQL-end?
 */
export enum Platform {
  Windows = 'WINDOWS',
  macOS = 'MACOS',
  Linux = 'LINUX',
  Android = 'ANDROID',
  iOS = 'IOS',
}
